import { SET_USERS_SUBSCRIPTION } from "./actionTypes";

const DEFAULT_DATA = {
  isApiCallInProgress: true,
  payload: null,
};
const initialState = {
  photos: {
    ...DEFAULT_DATA,
  },
};
const UsersSubscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USERS_SUBSCRIPTION:
      return {
        ...state,
        usersSubscription: {
          ...state.photos,
          ...action.value,
        },
      };
    default:
      return state;
  }
};

export default UsersSubscriptionReducer;
