import {
  SET_APP_ERRORS,
  SET_APP_PAGE_OPEN,
  SET_INTEREST_EXPRESS,
  SET_MISCELLANEOUS,
  SET_PAGE_OPEN,
} from "./actionTypes";

const DEFAULT_DATA = {
  isApiCallInProgress: true,
  payload: null,
};
const initialState = {
  pageOpen: {
    ...DEFAULT_DATA,
  },
  errors: {
    ...DEFAULT_DATA,
  },
  urlOpens: {
    ...DEFAULT_DATA,
  },
  interestExpress: {
    ...DEFAULT_DATA,
  },
  miscellaneousMatrix: {
    ...DEFAULT_DATA,
  },
};
const InsightsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAGE_OPEN:
      return {
        ...state,
        pageOpen: {
          ...state.pageOpen,
          ...action.value,
        },
      };
    case SET_APP_ERRORS:
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.value,
        },
      };
    case SET_APP_PAGE_OPEN:
      return {
        ...state,
        urlOpens: {
          ...state.urlOpens,
          ...action.value,
        },
      };
    case SET_INTEREST_EXPRESS:
      return {
        ...state,
        interestExpress: {
          ...state.interestExpress,
          ...action.value,
        },
      };
    case SET_MISCELLANEOUS:
      return {
        ...state,
        miscellaneousMatrix: {
          ...state.miscellaneousMatrix,
          ...action.value,
        },
      };
    default:
      return state;
  }
};

export default InsightsReducer;
