import { SET_ERROR } from "./actionTypes";

const DEFAULT_DATA = {
  isApiCallInProgress: true,
  payload: null,
};
const initialState = {
  errors: {
    ...DEFAULT_DATA,
  },
};
const ErrorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.value,
        },
      };
    default:
      return state;
  }
};

export default ErrorsReducer;
