import { SET_HOME } from "./actionTypes";

const DEFAULT_DATA = {
  isApiCallInProgress: true,
  payload: null,
};
const initialState = {
  getHome: {
    ...DEFAULT_DATA,
  },
};
const HomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HOME:
      return {
        ...state,
        getHome: {
          ...state.getHome,
          ...action.value,
        },
      };
    default:
      return state;
  }
};

export default HomeReducer;
