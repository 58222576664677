import { Outlet } from "react-router";
import "./App.scss";
import "./services/httpInterceptor";
import { createPortal } from "react-dom";
import React, { Suspense } from "react";

/** Lazy */
const DialogModal = React.lazy(() =>
  import("./components/organisms/DialogModal")
);
const Toast = React.lazy(() => import("./components/organisms/Toast"));
const AppLoader = React.lazy(() => import("./components/atoms/AppLoader"));
/** END - Lazy */
function App() {
  return (
    <>
      <Outlet />
      <Suspense fallback={<></>}>
        <BodyPortal portal={<DialogModal />} />
        <BodyPortal portal={<Toast />} />
      </Suspense>
      {/* App Loader */}
      <BodyPortal portal={<AppLoader />} />
    </>
  );
}

const BodyPortal = ({ portal }) => {
  return createPortal(portal, document.body);
};

export default App;
