import { SET_PHOTOS } from "./actionTypes";

const DEFAULT_DATA = {
  isApiCallInProgress: true,
  payload: null,
};
const initialState = {
  photos: {
    ...DEFAULT_DATA,
  },
};
const PhotosReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PHOTOS:
      return {
        ...state,
        photos: {
          ...state.photos,
          ...action.value,
        },
      };
    default:
      return state;
  }
};

export default PhotosReducer;
