import React from "react";
import Box from "../../atoms/Box";
import SocialImage from "../../../assets/images/404/social.jpg";
import ShadowImg from "../../atoms/ShadowImg/ShadowImg";
import Button from "../../atoms/Button";

const ErrorBoundary = () => {
  //Reload Screen
  function tryAgain() {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  return (
    <>
      <Box className="row">
        <Box className="container">
          <Box
            className="dft"
            height="100vh"
            justifyContent="center"
            flexDirection="column"
            p="20px"
          >
            <Box className="r ov" width="275px" height="200px" mb="40px">
              <ShadowImg src={SocialImage} alt="Error" />
            </Box>
            <Box as="h3" className="f3 f22" pb="10px">
              Oops! That’s an error.
            </Box>
            <Box as="p" className="f20 o2">
              Try reloading the page. We are working hard to fix for you as soon
              as possible.
            </Box>
            <Box pt="20px">
              <Button onClick={tryAgain}>Try Again</Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ErrorBoundary;
