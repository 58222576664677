import AppConfigReducer from "./appConfig/AppConfigReducer";
import DialogModalReducer from "../components/organisms/DialogModal/reducers";
import ToastReducer from "../components/organisms/Toast/reducers";
import UsersReducer from "../pages/Users/reducer";
import HomeReducer from "../pages/Home/reducer";
import PhotosReducer from "../pages/Photos/reducer";
import ErrorsReducer from "../pages/Errors/reducer";
import InsightsReducer from "../pages/Insights/reducer";
import UsersSubscriptionReducer from "../pages/UsersSubscription/reducer";

const rootReducers = {
  appConfig: AppConfigReducer,
  modal: DialogModalReducer,
  toast: ToastReducer,
  usersPage: UsersReducer,
  homePage: HomeReducer,
  photosPage: PhotosReducer,
  errorsPage: ErrorsReducer,
  insightsPage: InsightsReducer,
  usersSubscriptionPage: UsersSubscriptionReducer
};

export default rootReducers;
