import { LOGIN } from "../routing/routes.constant";

const tokenKey = "mtTok";
const pdidevice = "pdidevice";

export const token = () => {
  return localStorage.getItem(tokenKey);
};
export const setToken = (token) => {
  localStorage.setItem(tokenKey, token);
};
export const removeToken = () => {
  localStorage.removeItem(tokenKey);
};

export const authentication = () => {
  if (token()) {
    return true;
  } else {
    return false;
  }
};

export function loginSuccessfully(response, navigate, redirect) {
  //Set Token
  setToken(response?.authToken);
  //Check if user login first time
  navigate(redirect || "/");
  //Reload
  window.location.reload();
}

export function logoutSuccessfully(navigate) {
  removeToken();
  if (navigate) {
    navigate(LOGIN.fullUrl);
  } else {
    window.location.href = LOGIN.fullUrl;
  }
}

export function setPlatformData(data) {
  sessionStorage.setItem(pdidevice, JSON.stringify(data));
}

export function getPlatformData() {
  const pData = sessionStorage.getItem(pdidevice);
  return pData ? JSON.parse(pData) : null;
}
