import React from "react";
import { createBrowserRouter } from "react-router-dom";
import CrashBoundary from "../components/organisms/ErrorBoundary";
import App from "../App";
import {
  Dashboard,
  UsersPage,
  LoginPage,
  HomePage,
  UserListingPage,
  UserDetailsPage,
  PhotosPage,
  ErrorsPage,
  InsightsPage,
  UserSubscriptionPage,
  UsersDisputeRequest,
  UsersDisputeRequestListingPage,
  DocumentVerificationRequest,
  DocumentVerificationListingPage
} from "./LazyRoute";
import {
  ERRORS,
  INSIGHTS,
  LOGIN,
  PHOTOS,
  USERS,
  USERS_SUBSCRIPTION,
  SUBSCRIPTION,
  SUBSCRIPTION_CREATE,
  SUBSCRIPTION_INTEREST,
  USER_DISPUTE_REQUESTS,
  DOCUMENT_VERIFICATION
} from "./routes.constant";
import {
  SubscriptionPage,
  SubscriptionListingPage,
  SubscriptionCreatePage,
  SubscriptionInterestPage,
} from "./LazyRoute";

const AuthChildrenRoutes = [
  {
    path: "",
    element: (
      <React.Suspense fallback={<></>}>
        <HomePage />
      </React.Suspense>
    ),
  },
  {
    path: USERS.path,
    children: [
      {
        path: "",
        element: (
          <React.Suspense fallback={<></>}>
            <UserListingPage />
          </React.Suspense>
        ),
      },
      {
        path: "details/:userId",
        element: (
          <React.Suspense fallback={<></>}>
            <UserDetailsPage />
          </React.Suspense>
        ),
      },
    ],
    element: (
      <React.Suspense fallback={<></>}>
        <UsersPage />
      </React.Suspense>
    ),
  },
  {
    path: PHOTOS.path,
    element: (
      <React.Suspense fallback={<></>}>
        <PhotosPage />
      </React.Suspense>
    ),
  },
  {
    path: ERRORS.path,
    element: (
      <React.Suspense fallback={<></>}>
        <ErrorsPage />
      </React.Suspense>
    ),
  },
  {
    path: INSIGHTS.path,
    element: (
      <React.Suspense fallback={<></>}>
        <InsightsPage />
      </React.Suspense>
    ),
  },
  {
    path: USERS_SUBSCRIPTION.path,
    element: (
      <React.Suspense fallback={<></>}>
        <UserSubscriptionPage />
      </React.Suspense>
    ),
  },
  {
    path: SUBSCRIPTION.path,
    element: (
      <React.Suspense fallback={<></>}>
        <SubscriptionPage />
      </React.Suspense>
    ),
    children: [
      {
        path: "",
        element: (
          <React.Suspense fallback={<></>}>
            <SubscriptionListingPage />
          </React.Suspense>
        ),
      },
      {
        path: SUBSCRIPTION_CREATE.path,
        element: (
          <React.Suspense fallback={<></>}>
            <SubscriptionCreatePage />
          </React.Suspense>
        ),
      },
      {
        path: SUBSCRIPTION_INTEREST.path,
        element: (
          <React.Suspense fallback={<></>}>
            <SubscriptionInterestPage />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    path: USER_DISPUTE_REQUESTS.path,
    element: (
      <React.Suspense fallback={<></>}>
        <UsersDisputeRequest />
      </React.Suspense>
    ),
    children: [
      {
        path: "",
        element: (
          <React.Suspense fallback={<></>}>
            <UsersDisputeRequestListingPage />
          </React.Suspense>
        ),
      },
    ]
  },
  {
    path: DOCUMENT_VERIFICATION.path,
    element: (
      <React.Suspense fallback={<></>}>
        <DocumentVerificationRequest />
      </React.Suspense>
    ),
    children: [
      {
        path: "",
        element: (
          <React.Suspense fallback={<></>}>
            <DocumentVerificationListingPage />
          </React.Suspense>
        ),
      },
    ]
  },
];

const ChildrenRoutes = [
  {
    path: "",
    element: (
      <React.Suspense fallback={<></>}>
        <Dashboard />
      </React.Suspense>
    ),
    children: [...AuthChildrenRoutes],
  },
  {
    path: LOGIN.path,
    element: (
      <React.Suspense fallback={<></>}>
        <LoginPage />
      </React.Suspense>
    ),
  },
  {
    path: "*",
    element: (
      <React.Suspense fallback={<></>}>{/* <PageNotFound /> */}</React.Suspense>
    ),
  },
];

const AppRouter = createBrowserRouter([
  {
    path: "",
    element: <App />,
    errorElement: <CrashBoundary />,
    children: [...ChildrenRoutes],
  },
]);

export default AppRouter;
