import React from "react";

/** Lazy */
export const LoginPage = React.lazy(() => import("../pages/Login"));
export const Dashboard = React.lazy(() => import("../pages/Dashboard"));
export const UsersPage = React.lazy(() => import("../pages/Users"));
export const UserListingPage = React.lazy(() => import("../pages/Users/Pages/UserListing"));
export const UserDetailsPage = React.lazy(() => import("../pages/Users/Pages/UserDetails"));
export const HomePage = React.lazy(() => import("../pages/Home"));

export const PhotosPage = React.lazy(() => import("../pages/Photos"));
export const ErrorsPage = React.lazy(() => import("../pages/Errors"));
export const InsightsPage = React.lazy(() => import("../pages/Insights"));
export const UserSubscriptionPage = React.lazy(() => import("../pages/UsersSubscription"));
export const SubscriptionPage = React.lazy(() => import("../pages/Subscription"));
export const SubscriptionListingPage = React.lazy(() => import("../pages/Subscription/Pages/Listing"));
export const SubscriptionCreatePage = React.lazy(() => import("../pages/Subscription/Pages/Create"));
export const SubscriptionInterestPage = React.lazy(() => import("../pages/Subscription/Pages/Interest"));
export const UsersDisputeRequest = React.lazy(() => import("../pages/UserDisputeRequests"));
export const UsersDisputeRequestListingPage = React.lazy(() => import("../pages/UserDisputeRequests/Pages/Listing"));
export const DocumentVerificationRequest = React.lazy(() => import("../pages/DocumentVerification"));
export const DocumentVerificationListingPage = React.lazy(() => import("../pages/DocumentVerification/Pages/Listing"));
/** END - Lazy */
