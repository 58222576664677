import axios from "axios";
import { logoutSuccessfully } from "../../helper/authorization";
import { getAuthorizationHeaders } from "./auth/authService";

//Default timeout
axios.defaults.timeout = 15000;
//Interceptor
axios.interceptors.request.use(
  function (config) {
    config.headers = {
      ...config.headers,
      ...getAuthorizationHeaders(),
    };
    //Return
    return config;
  },
  //Error
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    //Return
    return response?.data;
  },
  function (error) {
    errorState(error);
    //Return
    return Promise.reject(getErrorData(error));
  }
);

const getErrorData = (err) => {
  return err?.response?.data || err;
};

/*------Error handling-------*/
function errorState(error) {
  emitEvent(error);
  if (error?.code === "ERR_NETWORK") {
    //Network error
  } else if (error?.code === "ECONNABORTED") {
    //Api Timeout
  } else if (error?.request?.status === 401) {
    logoutSuccessfully();
  }

  function emitEvent(error) {
    const errorObject = {
      code: error?.code,
      apiUrl: error?.config?.url,
      apiData: error?.response?.data || {},
    };
  }
}
